/**
 * Adjustments to base non-typographic html elements
 */

html {
    scroll-padding: 3rem;
}
@media (prefers-reduced-motion: no-preference) {
    html {
        scroll-behavior: smooth;
    }
}

/* HTML safety */
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

*,
:before,
:after {
    box-sizing: border-box;
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

button {
    cursor: pointer;
    font-size: inherit;
    line-height: inherit;
}

mark {
    background-color: rgba($yellow, 0.25);
    padding: 0 0.15em;
    border-radius: 3px;
}

.mark--negative {
    background-color: rgba($red, 0.25);
}

.mark--positive {
    background-color: rgba($green, 0.25);
}
