.phone-preview {
    position: relative;
    max-width: 330px;
    min-width: 250px;
    margin: 0 auto;
    background: #0066cc linear-gradient(#0066cc 10%, #fff 10%); // pseudo app background and accounts for gap above photo where notch is

    @include mq-medium {
        flex: 1 0 auto;
        margin-right: auto;
    }

    /*  The phone frame takes up an off-center portion of the image.
		:before sets the aspect ratio of the phone itself
		:after is adjusted by percentages to position the phone within the container

		Image: 900 x 1290
		Phone: 644 x 1192
		Phone top-left corner offset: 78x, 98y

		Math below is for clarity.
	*/

    // aspect ratio sizing
    &:before {
        display: block;
        content: "";
        width: 100%;
        height: 0;
        padding-top: (1192 / 644) * 100%;
    }

    // the phone overlay
    &:after {
        position: absolute;
        display: block;
        content: "";
        width: (900 / 644) * 100%;
        height: (1290 / 1192) * 100%;
        top: -1 * (98 / 1192) * 100%;
        left: -1 * (78 / 644) * 100%;
        background: url("../img/phone-frame.png") top left / 100% no-repeat;
    }

    img {
        display: block;
        position: absolute;
        top: 4%; // account for notch & bevel
        left: 2%; // account for bevel
        width: 96%;
        height: 96%;
    }

    @include mq-medium {
        margin-left: 3em;
    }

    @include mq-full {
        margin-left: 6em;
    }
}
