.site-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fff;
    z-index: 99;
}

.site-title {
    flex: 0 1 75%;
    margin-top: 0.25em;
    margin-bottom: 0.125em;
    align-items: center;
    display: flex;

    a {
        display: block;
    }

    @include mq-small {
        flex-basis: auto;
    }
}

.site-logo {
    max-width: 180px;
}
.site-logo--secondary {
    max-width: 125px;
    padding-left: 0.5em;
    margin-left: 0.5em;
    border-left: 1px solid $gray;
}
