/*======================================
=            Layout Classes            =
======================================*/
.flow-inline > * + * {
    margin-left: 1.5em;
}

.flow-block > * + * {
    margin-top: 1.25em;
}

.flow-block--spacious {
    margin-top: 4em;
}

.margin-top-0 {
    margin-top: 0 !important;
}

/*==============================
=            Colors            =
==============================*/
.bg-light-gray {
    background: $gray--light;
}

/*=======================================
=            Text Formatting            =
=======================================*/
.detail {
    font-size: 0.875em;
    color: $gray--dark;
}
