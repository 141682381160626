html,
body {
    padding: 0;
    margin: 0;
}

.site {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
}

.site-main {
    border-top: 1px solid $gray;
}

.page-top:before,
.page-top:after {
    // clearfix to contain first element margin within background
    display: table;
    content: "";
}

.container {
    max-width: $width-page;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1.5em;

    @include mq-small {
        padding: 0 2.5em;
    }

    @include mq-medium {
        padding: 0 4em;
    }

    @include mq-full {
        padding: 0;
    }
}

/* These apply visual padding to the container sections on the page only if they have children. Main restricts that to avoid header and footer changes */
main .container > :last-child {
    margin-bottom: 32px;

    @include mq-medium {
        margin-bottom: 56px;
    }
}
main .container > :first-child {
    margin-top: 32px;

    @include mq-medium {
        margin-top: 56px;
    }
}

// temporarily rolling this back until markup is improved so that inline and inline-block elements are never direct descendants of .container (they shouldn't ever be)
/* main .container > :last-child {
	padding-bottom: 1.5em;

	@include mq-medium {
		padding-bottom: 2.5em;
	}
} */

.container__content {
    max-width: $width-content;
}

.container__content--narrow {
    max-width: $width-content-narrow;
}

// apply this style with .flow-block on any div, from _utilities.scss
main .container > * + *,
main .container__content > * + * {
    margin-top: 1.25em;
}
