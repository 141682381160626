/*========================================
=            Base Type Styles            =
========================================*/
body {
    /* system font stack, see https://css-tricks.com/snippets/css/system-font-stack/ */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    line-height: 1.5;
    color: $black;
}

/*================================
=            Headings            =
================================*/
h1,
.h1 {
    font-size: 2em;
    line-height: 1.25;

    @include mq-medium {
        font-size: 2.5em;
        line-height: 1.5;
    }
}

body .notification + h1 {
    margin-top: 0.5em;
}

h2,
.h2 {
    font-size: 1.5em;
    line-height: 1.3333;

    @include mq-medium {
        font-size: 2em;
        line-height: 1.25;
    }
}

h3,
.h3 {
    font-size: 1.25em;
    line-height: 1.4;
    font-weight: 300;

    @include mq-medium {
        font-size: 1.5em;
        line-height: 1.5;
    }
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-size: 1em;
    font-weight: 300;
}

.heading--underlined {
    margin-top: 2em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid $gray;
    border-color: rgba($black, 0.075);
}

/*=============================
=            Lists            =
=============================*/
ul li::marker {
    color: $teal;
}

main li + li {
    margin-top: 0.25em;
}

/*=============================
=            Links            =
=============================*/
a {
    color: $teal--dark;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:visited {
        color: #830c75;
    }

    &:active {
        color: $teal;
    }
}
