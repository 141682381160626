/**
 * Basic Table Styles
 *
 * Opted into with .table classe
 *
 * wrapped in :root to take precedence over dataTables styles
 */
:root {
    .table {
        width: 100%;
        margin-bottom: 1rem;
        border: 0 !important;
        border-collapse: collapse;
    }

    .table tr {
        border-bottom: 1px solid $gray;
    }

    .table th,
    .table td {
        padding: 0.5rem 1rem;
        vertical-align: middle;
        border: 0;

        &.no-horiz-padding {
            padding: 0.5rem 0;
        }
    }

    /* Table Head */
    .table thead th {
        text-align: left;
        vertical-align: bottom;
        white-space: nowrap;
    }

    /* Elements in Tables */
    th {
        text-align: left;
    }

    .table ul {
        list-style-type: none;
        padding-left: 0px;
    }

    .table .button {
        @include button--mini;
    }

    .table .truncated-value {
        display: block;
        max-width: 12em; // ~25 characters
        min-width: 100%; // always fill full table cell
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    /* Table Style Variations */
    .table--align-top td,
    .table--align-top th {
        vertical-align: top;
    }
} // end :root

/**
 * Response Tables Wrapper
 *
 * See: https://adrianroselli.com/2020/11/under-engineered-responsive-tables.html
 */
.responsive-table-wrapper[role="region"][tabindex] {
    overflow: auto;

    // horizontal scrolling shadow hint
    // props: https://codepen.io/huijing/details/XBGaNQ
    background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0 100%,
        radial-gradient(
            farthest-side at 0% 50%,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
        ),
        radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0)
            )
            0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
    background-position: 0 0, 100%, 0 0, 100%;
    background-attachment: local, local, scroll, scroll;

    &:focus {
        outline: 0.1em solid rgba(0, 0, 0, 0.1);
    }
}
