/*
 * Mixins are primarily used so we can include styles in places where it's hard to adjust the markup (link in dataTables)
 */

@mixin button--mini {
    padding: 0.25em 0.75em;
    font-weight: 400;
    min-width: auto;
}

@mixin input--compact {
    padding: 0.3125em 0.5em;
}

@mixin select--compact {
    padding: 0.3125em 1.75em 0.3125em 0.5em;
}

@mixin icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "be-icons" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
