// Media Queries
@mixin mq-small {
    @media (min-width: 32em) {
        // 512px
        @content;
    }
}

@mixin mq-medium {
    @media (min-width: 48em) {
        // 768px
        @content;
    }
}

@mixin mq-full {
    @media (min-width: 71em) {
        // 1136px
        @content;
    }
}
