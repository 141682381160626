/**
 * Classes used to modify the icons from the icon font in _icon-font.scss
 */

/* Colors */
/* By default, icon inherits font color */
.icon--yellow:before {
    color: $yellow;
}
.icon--teal:before {
    color: $teal;
}
.icon--red:before {
    color: $red;
}
.icon--white:before {
    color: #fff;
}
.icon--gray:before {
    color: $gray--dark;
}
.icon--black:before {
    color: $black;
}

// don't underline icons that are part of a link
a .icon {
    display: inline-block;
    text-decoration: none;
}
