/* Copied from be-icons-v1.2/stylesheet.css */
/* Modified only to fix relative path to fonts folder */
@font-face {
    font-family: "be-icons";
    src: url("../fonts/be-icons.ttf?cqrxk") format("truetype"),
        url("../fonts/be-icons.woff?cqrxk") format("woff"),
        url("../fonts/be-icons.svg?cqrxk#be-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "be-icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: "\f002";
}
.icon-user:before {
    content: "\f007";
}
.icon-check:before {
    content: "\f00c";
}
.icon-close:before {
    content: "\f00d";
}
.icon-remove:before {
    content: "\f00d";
}
.icon-gear:before {
    content: "\f013";
}
.icon-download:before {
    content: "\f019";
}
.icon-list:before {
    content: "\f022";
}
.icon-lock:before {
    content: "\f023";
}
.icon-flag:before {
    content: "\f024";
}
.icon-pencil:before {
    content: "\f040";
}
.icon-check-circle:before {
    content: "\f058";
}
.icon-question-circle:before {
    content: "\f059";
}
.icon-info-circle:before {
    content: "\f05a";
}
.icon-ban:before {
    content: "\f05e";
}
.icon-exclamation-circle:before {
    content: "\f06a";
}
.icon-exclamation-triangle:before {
    content: "\f071";
}
.icon-warning:before {
    content: "\f071";
}
.icon-calendar:before {
    content: "\f073";
}
.icon-upload:before {
    content: "\f093";
}
.icon-twitter:before {
    content: "\f099";
}
.icon-facebook:before {
    content: "\f09a";
}
.icon-globe:before {
    content: "\f0ac";
}
.icon-chain:before {
    content: "\f0c1";
}
.icon-link:before {
    content: "\f0c1";
}
.icon-menu:before {
    content: "\f0c9";
}
.icon-linkedin:before {
    content: "\f0e1";
}
.icon-comment:before {
    content: "\f0e5";
}
.icon-comments:before {
    content: "\f0e6";
}
.icon-sitemap:before {
    content: "\f0e8";
}
.icon-arrow-down:before {
    content: "\f107";
}
.icon-code-fork:before {
    content: "\f126";
}
.icon-building:before {
    content: "\f1ad";
}
.icon-history:before {
    content: "\f1da";
}
.icon-whatsapp:before {
    content: "\f232";
}
