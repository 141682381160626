$teal: #16ad9c;
$teal--dark: #0c8572; // 4.55:1 color contrast
$yellow: #f4bc29;
$yellow--dark: #e89d00;
$red: #de5656;
$gray: #dcdcdc;
$green: #56dd5d;
$gray--light: #f9f9f9;
$gray--dark: #5c5c62;
$gray--black: #3a3d43;
$black: #1f272e;

/*
 * Notification State Color
 */
$danger: #f8d8db;
$warning: #faf9ca;
$info: #d1e0ff;
$success: #d2efd8;
