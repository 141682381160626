/* Container for multiple buttons or button(s) with help text */
.buttons {
    > * {
        margin-top: 1.25em;
        margin-right: 1.25em;

        @include mq-small {
            margin-top: 0;
        }
    }
}

@include mq-small {
    .buttons--reverse {
        display: flex;
        flex-flow: row-reverse wrap;
        justify-content: flex-end;

        > * + * {
            margin-top: 0;
            margin-left: 0;
            margin-right: 1.25em;
        }
    }
}

.buttons__help {
    display: inline-block;
    color: $gray--dark;
}

.button,
.button:visited {
    display: inline-block;
    min-width: 8em;
    padding: 0.6125em 1.5em;
    background-color: $teal--dark;
    border: 1px solid $teal--dark;
    color: #fff;
    font-weight: 700;
    text-align: center;
    text-decoration: none;

    &.active, // temporary style until dealing with this more fully
    &:hover,
    &:focus {
        outline: 0.125em solid $black;

        @supports (outline-offset: 0.25em) {
            outline: 0.125em solid currentColor;
            outline-offset: -0.25em;
        }
    }

    @include mq-small {
        width: auto;
    }
}

.button .icon {
    margin-right: 0.5em;
}

.button--mini {
    @include button--mini;
}

.button--naked,
.button--naked:visited {
    background-color: transparent;
    border: 0;
    min-width: auto;
    padding: 0.5em;
    color: inherit;
}

.button--yellow,
.button--yellow:visited {
    background-color: $yellow;
    border-color: $yellow;
    color: $black;
}

.button--gray {
    background-color: $gray--dark;
    border-color: $gray--dark;
}

.button--red {
    background-color: $red;
    border-color: $red;
}
