#notification-drawer[aria-live] {
    position: fixed;
    left: 1.5rem;
    bottom: 1.5rem;
    box-shadow: 1px 1px 1px rgba($black, 0.15);
    background-color: #fff;
    z-index: 1;
}

.notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 0 1em;
    padding: 0.5em;
    background-color: $info;
    color: #111; // slightly darker than $black to try to match visual ratio of bg to text color for $black on #fff

    .notification__icon:before {
        content: "\f05a"; // icon-info-circle
    }

    .icon {
        font-size: 21px;
        margin-right: 0;
    }

    @include mq-medium {
        flex-wrap: nowrap;
    }

    > * {
        margin: 0.5em 0.75em;
    }

    a,
    a:visited {
        color: $teal;
        font-weight: 700;
    }
}

.notification--warning {
    background-color: $warning;

    .notification__icon:before {
        content: "\f06a"; // icon-exclamation-circle
    }
}

.notification--danger {
    background-color: $danger;

    .notification__icon:before {
        content: "\f071"; // icon-warning
    }
}

.notification--success {
    background-color: $success;

    .notification__icon:before {
        content: "\f058"; // icon-check-circle
    }
}

.notification--small {
    font-size: 0.875rem;
}

/**
 * DANGER: Changing order is rarely a good idea for accessibility. In this case, because only one element is focusable and this doesn't drastically differ from source order, we should be ok here.
 */
.notification__icon {
    order: 1;
}

.notification__message {
    order: 3;
    margin: 0 auto 0 0;
    padding: 0.5em 0.75em;
}

.notification__dismiss {
    margin-left: auto;
    margin-right: 0;
    padding: 0.5em 0.75em;
    order: 2;

    @include mq-medium {
        order: 4; // after 3
    }
}
