.template_home .page-top,
.route_home .page-top {
    overflow-x: hidden;
}

main .container .home-top {
    padding-bottom: 0;
    margin-bottom: 0;

    @include mq-medium {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.home-top .container__content {
    // ensure phone graphic doesn't cover text
    position: relative;
    z-index: 1;
    padding-bottom: 4em;
}
