/* Modified version of https://make.wordpress.org/accessibility/handbook/markup/the-css-class-screen-reader-text/ */
.screen-reader-text {
    position: absolute;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    word-wrap: normal !important;
}
.screen-reader-text:focus {
    display: block;
    clip: auto !important;
    clip-path: none;
    height: auto;
    width: auto;
    margin: 0;
    z-index: 100000;
}
