.menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Menu Display Logic */
.site-navigation__toggle {
    display: inline-block;
}

.site-navigation__menu {
    display: none;
}

.site-navigation__toggle[aria-expanded="true"] + .site-navigation__menu {
    display: block;

    @include mq-small {
        display: flex;
    }
}

@include mq-medium {
    .site-navigation__toggle {
        display: none;
    }
    .site-navigation__menu {
        display: block;
    }
}

/* Mobile Menu */
.site-navigation__toggle {
    position: relative;
    height: 3.5em;
    width: 3.5em;
    padding: 0;
    background-color: #fff;
    border: 0.25em solid $teal;
    color: $teal;

    .icon {
        font-size: 1.5em;
    }
}

.site-navigation__toggle[aria-expanded="true"] {
    background-color: $teal;
    color: #fff;

    .icon:before {
        content: "\f00d"; // .icon-close
    }
}

/* Main Menu */
.site-navigation__menu {
    // mobile display
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1em;
    background-color: $gray--light;
    border: solid $gray;
    border-width: 1px 0;

    li {
        padding: 1.5em;
        border-bottom: 1px solid $gray;
        text-align: center;
    }

    a:not(.button) {
        display: block;
        text-align: center;
        color: $black;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $teal--dark;
        }
    }

    @include mq-small {
        justify-content: space-between;

        li {
            display: flex;
            align-items: center;
            padding: 0 1.5em;
            border-bottom: 0;
        }

        li + li {
            margin-left: 1em;
        }
    }

    @include mq-medium {
        position: static;
        display: flex;
        flex-wrap: nowrap;
        padding: 0;
        background-color: transparent;
        border: 0;

        li {
            padding: 0;
        }

        li + li {
            margin-left: 2em;
        }
    }
}
