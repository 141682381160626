.site-footer {
    margin-top: 4em;
    background-color: $gray--black;
    color: #fff;

    @include mq-medium {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
    }

    a,
    a:visited {
        color: #fff;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .separator {
        margin-left: 0.5em;
        margin-right: 0.5em;
    }
}

.site-footer__copyright {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5em;
    background-color: $black;
    font-weight: 700;

    @include mq-medium {
        padding: 1.5em 3em;
        justify-content: flex-end;
    }
}

.site-footer__navigation {
    flex: 2 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-navigation__menu {
    padding: 1.5em 0;

    a {
        display: block;
        padding: 1em 1.5em;
        text-align: center;
    }

    @include mq-small {
        display: flex;
        justify-content: center;

        li + li {
            margin-left: 2.5em;
        }
    }

    @include mq-medium {
        a {
            padding: 0;
        }
    }
}

.site-footer__social {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 1.5em;

    @include mq-medium {
        padding: 1.5em 0;
    }
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq-medium {
        justify-content: flex-start;
    }
}

a.social-icons__icon,
a.social-icons__icon:visited {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.25em;
    height: 2.25em;
    border-radius: 100%;
    background-color: $yellow;
    border: 1px solid $yellow;
    color: $black;

    &:hover,
    &:focus {
        background-color: $black;
        text-decoration: none;

        .icon {
            color: $yellow;
        }
    }
}
