/* General Form Spacing */
.deform > * + * {
    margin-top: 1em;
}

.form--roomy > * + * {
    margin-top: 3em;
}

.field > * + * {
    margin-top: 0.25em;
}

.field--roomy > * + * {
    margin-top: 0.75em;
}

.field--roomy .form-control {
    margin-top: 1.5em;
}

.field__label {
    display: block;
    font-size: 1.125em;
}

// the input
label + .form-control {
    margin-top: 0.5em;
}

.field__label__required {
    color: darken($red, 20%); // ensure contrast on red background
}

.field__help {
    max-width: $width-content;
    font-size: 0.875em;
    color: $gray--dark;

    ul,
    ol {
        padding-left: 1em;
        margin-bottom: 1em;
    }

    li + li {
        margin-top: 0.5em;
    }

    ::marker {
        color: inherit;
    }
}

.field__help--error {
    font-weight: 700;
    color: darken($red, 20%); // ensure contrast on red background
}

.has-error {
    padding: 1em;
    background-color: $danger;
    border: 1px solid $red;
}

/*====================================
=            Input Styles            =
====================================*/

/*
 * General Styles & Text-like inputs
 */

[type="text"],
[type="tel"],
[type="email"],
[type="url"],
[type="password"],
[type="search"],
select,
body .select2-container--default .select2-selection--multiple,
body .select2-container--default .select2-selection--single,
textarea {
    width: 100%;
    max-width: $width-content;
    padding: 0.625em 0.875em;
    border: 1px solid $gray;
    font-family: inherit;
    font-size: inherit;

    &:hover {
        border-color: $gray--dark;
    }

    &:focus {
        border-color: $teal--dark;
    }

    .has-error & {
        border-color: $gray--dark;
    }
}

.input--compact {
    @include input--compact;
}

/*
 * Select element & Select2 styles to match it
 *
 * Thank you https://www.filamentgroup.com/lab/select-css.html
 */
select {
    display: block;
    padding: 0.625em 2.25em 0.625em 0.875em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg height='16' width='16' xmlns='http://www.w3.org/2000/svg' viewBox='-255 347 100 100'%3E%3Cpath fill='%2316ad9c' d='M-166.397 372.89L-205 421.11l-38.603-48.22z'/%3E%3C/svg%3E"),
        linear-gradient(#fff, #fff);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto, 100%;

    &::ms-expand {
        display: none;
    }
}

select.select--compact {
    @include select--compact;
}

[multiple="true"] {
    padding: 1em;
    background: linear-gradient(#fff, #fff);
}

/*
 * Select2 Styles
 *
 * body selector is specificity tiebreaker
 */

// this and next weird selector target online the in source flow select2, not the options at the end of the DOM
select + .select2-container {
    margin-top: 0.25em;
}

.field--roomy select + .select2-container {
    margin-top: 0.75em;
}

body .select2-container--default .select2-selection--multiple,
body .select2-container--default .select2-selection--single {
    height: 2.75em;
    border-radius: 0 !important;
}

body
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    width: 2.75em;
    height: 2.75em;
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg height='16' width='16' xmlns='http://www.w3.org/2000/svg' viewBox='-255 347 100 100'%3E%3Cpath fill='%2316ad9c' d='M-166.397 372.89L-205 421.11l-38.603-48.22z'/%3E%3C/svg%3E")
        1em no-repeat;

    b {
        // the default arrow
        display: none;
    }
}

body
    .select2-container
    .select2-selection--single
    .select2-selection__rendered {
    padding-left: 0;
    line-height: 1.25;
}

body
    .select2-container--default
    .select2-search--dropdown
    .select2-search__field {
    bordre-color: $gray;

    &:hover {
        border-color: $gray--dark;
    }

    &:focus {
        border-color: $teal;
    }
}

body .select2-dropdown {
    border-color: $gray;
    border-radius: 0;
}

/**
 * Radios and Checkboxes
 */
ul.form-control {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    padding-top: 1px; // hack to make margin-top apply even when previous element is a floated legend

    li {
        margin-top: 1em;
    }
}

/* Using screen reader text styles to hide actual inputs */
[type="checkbox"],
[type="radio"] {
    position: absolute;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    word-wrap: normal !important;
}

[type="checkbox"] + label,
[type="radio"] + label,
.select-button__radio {
    display: inline-block;
    position: relative;
    padding-left: 2em;
    cursor: pointer;

    &:before {
        position: absolute;
        top: 0.1875em;
        left: 0;
        display: block;
        content: "";
        width: 1.25em;
        height: 1.25em;
        border: 2px solid $gray;
        background: #fff;
    }

    .has-error &:before {
        border-color: $gray--dark;
    }
}

[type="checkbox"] + label:hover:before,
[type="radio"] + label:hover:before,
.select-button:hover .select-button__radio:before {
    border-color: $gray--dark;
}

[type="checkbox"]:focus + label:before,
[type="radio"]:focus + label:before,
[type="radio"]:focus + .select-button .select-button__radio:before {
    border-color: $teal;
}

[type="checkbox"]:checked + label:before {
    border-color: $teal;
    background-color: $teal;
    color: #fff;

    // styles from .icon
    font-family: "be-icons" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f00c"; // checkmark
}

[type="radio"] + label:before,
.select-button__radio:before {
    border-radius: 50%;
}

[type="radio"]:checked + label:before,
[type="radio"]:checked + .select-button .select-button__radio:before {
    border: 0.4375em solid $teal;
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
}

legend {
    padding: 0;

    // fix for position! https://stackoverflow.com/a/29652463/597678
    float: left;
    + * {
        clear: left;
    }
}

/**
 * Site Select "Button"
 */
body .select-button {
    // generic

    display: block;
    width: 100%;
    max-width: $width-content;
    padding: 1em;
    border: 1px solid $gray;
    box-shadow: 0 8px 12px rgba($black, 0.08);

    @include mq-medium {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // reset radio styles
    // radio button circles added with selectors in the default radio styles above
    &:before,
    &:after {
        display: none !important;
    }
}

.select-button__value {
    display: block;
}

.select-button__button {
    display: block;
    text-align: center;
    margin-top: 1em;

    @include mq-medium {
        margin-top: 0;
    }
}

label.select-button {
}

[type="radio"]:checked + .select-button {
    background-color: $teal--dark;
    border-color: $teal--dark;
    color: #fff;
}

[type="radio"]:checked + .select-button .select-button__button {
    border-color: $teal--dark;
}

label .select-button__button {
    padding: 0.5em 1em 0.5em 0.75em;
    border: 2px solid $teal;
    background-color: #fff;
    color: $black;
    white-space: nowrap;
}

div.select-button {
}

div .select-button__button a {
    font-weight: normal;
}

/**
 * Additional Styles for Likerts
 */
@include mq-medium {
    .likert-row {
        display: flex;
    }

    .likert-item {
        flex: 1 0 auto;
        display: flex;
        justify-content: center;

        [type="checkbox"] + label,
        [type="radio"] + label {
            padding-left: 0;
            padding-top: 1.5em;

            &:before {
                top: 0;
                left: 50%;
                margin-left: -0.625em;
            }
        }
    }
}

/*====================================================================
=            Single-Field Presentational / Layout Classes            =
=====================================================================*/

/* Text-like Field Sizes */
.field--small input,
.field--small textarea,
[inputmode="decimal"] {
    max-width: 8em;
}

.field--medium input,
.field--medium textarea,
[type="tel"] {
    max-width: 20em;
}

.field--full input,
.field--full textarea {
    max-width: 100%;
}

/* Field Spacing */
.field--pull-up {
    margin-top: 0.25em;
}

.form--roomy .field--pull-up {
    margin-top: 1em;
}

/* Radio & Checkbox Layouts */
.field--items-multicol .form-control {
    margin-top: 1em;
    column-gap: 3em;

    li {
        page-break-inside: avoid;
        break-inside: avoid;
    }

    li:first-of-type {
        margin-top: 0;
    }

    @include mq-small {
        column-count: 2;
    }

    @include mq-medium {
        column-count: 3;
    }

    @include mq-full {
        column-count: 4;
    }
}

@include mq-medium {
    .field--items-inline {
        .form-control {
            margin-top: 0.5em;
        }

        li {
            display: inline-block;
        }

        li + li {
            margin-left: 3em;
        }
    }
}

/*==================================================
=            Multi-Field Layout Classes            =
==================================================*/
@include mq-medium {
    .field--left-half,
    .field--right-half {
        width: 48%;
        float: left;
    }

    .field--left-half {
        clear: left;
        margin-right: 4%;
        margin-bottom: 1em;
        margin-top: 1em;
    }

    .form--roomy .field--left-half {
        margin-top: 3em;
        margin-bottom: 3em;
    }

    .field--right-half + * {
        clear: both;
    }
}

/*=======================================
=            TinyMCE                    =
=======================================*/
div.mce-tinymce {
    display: block !important; // has to override an inline style
    margin-top: 1em;
}

/*=======================================
=            jQuery UI Fixes            =
=======================================*/
/* Migrated from overrides.css */
.ui-menu-item-wrapper {
    /* Make autocomplete items fill width */
    display: block;
}
